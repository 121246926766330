import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import CopyAll from '@mui/icons-material/CopyAll';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import CheckIcon from '@mui/icons-material/Check';
import Paper from '@mui/material/Paper';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 25,
    },
  },
};

const names = [
    {name: 'error', icon: <ErrorIcon/>},
    {name: 'incomplete', icon:<TimerOffIcon/>},
    {name: 'duplicate', icon:<CopyAll/>},
    {name: 'long_name', icon:<FormatItalicIcon/>}
];

function getStyles(name, personName, theme) {
    if (personName) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
    }
}

export default function MultipleSelect({inTags=[], saveTag, setSelectedTags, selectedTags}) {
  const theme = useTheme();
  const [needSave, setNeedSave] = React.useState(false);
 
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value)
    setSelectedTags(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    if (JSON.stringify(value) !== JSON.stringify(inTags)) {
        setNeedSave(true)
    }
  };
  React.useEffect(()=>{
      console.log(selectedTags, inTags)
    if (selectedTags.length< 1) {
        setNeedSave(false)
    }  
  }, [inTags])

  return (
    <Paper sx={{ display: { xs: 'none', md: 'block' }, boxShadow:0 }} >
      <FormControl sx={{ m: 1, width: 75 }}>
        <InputLabel id="demo-multiple-name-label"><WarningIcon/></InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={selectedTags}
          onChange={handleChange}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem
              key={name.name}
              value={name.name}
              style={getStyles(name, selectedTags, theme)}
            >
              {name.icon}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {needSave && <CheckIcon onClick={saveTag} style={{color:'black', cursor:'pointer',  marginLeft:10}} />}
    </Paper>
  );
}
