import React, { createContext, useContext,  useState, useEffect } from 'react';
const ListContext = createContext();

const useListContext = () => {
    return useContext(ListContext);
};

const ListProvider = ({ children }) => {
    const [listData, setListData] = useState({total:0, result: []});
    const [loading, setLoading] = useState(false)
    const [listState, setListState] = useState({
        page:0,
        sortDir: "desc",
        sortField: "date",
        searchKey: '',
        searchName: '',
        filters: {},
    }); // Adjust as needed
    const getData = async () =>{
        try {
            setLoading(true);
            let url = `/api/list?skip=${listState.page*100}&sort=${listState.sortField}&sortDir=${listState.sortDir}`;
            if (listState.searchKey) {
              url += `&name=${listState.searchKey}`
            }
            if (listState.searchName) {
              url += `&searchName=${listState.searchName}`
            }
            if (listState.filters && listState.filters.source) {
                url += `&fSource=${listState.filters.source}`
            }
            if (listState.filters &&  listState.filters.tags && listState.filters.tags.length > 0) {
                url += '&'
                url +=listState.filters.tags.map(tag=> `tag[]=${tag}`).join('&')
            }
            if (listState.filters &&  listState.filters.label && listState.filters.label.length > 0) {
                url += '&'
                url +=listState.filters.label.map(_label=> `fLabel[]=${_label}`).join('&')
            }
            const req = await fetch(url)
            const _data = await req.json()
            setLoading(false)
            setListData(_data)
            
        } catch (e) {
            setLoading(false)
            console.log(e)
        }
    }
    // Fetch data based on the listState
    useEffect(() => {
        getData()
    }, [listState]);

    return (
    <ListContext.Provider value={{ listData, setListState, listState, loading, setLoading }}>
    {children}
    </ListContext.Provider>
    );
};

  export { useListContext, ListProvider }