/*import React, { createContext,  useState } from 'react';
const LabelContext = createContext();

const LabelProvider = ({ children }) => {
    const [labels, setLabels] = useState([]);
  
    return (
      <LabelContext.Provider value={{ labels, setLabels }}>
        {children}
      </LabelContext.Provider>
    );
  };

  export {LabelContext, LabelProvider}*/

import React, { createContext, useContext,  useState, useEffect } from 'react';
const LabelContext = createContext();

const useLabelContext = () => {
    return useContext(LabelContext);
};

const LabelProvider = ({ children }) => {
    const [labels, setLabels] = useState([]);
    
    useEffect(()=>{
        const getData = async () => {
            const url = `/api/labels`;
            const req = await fetch(url)
            const _data = await req.json()
            setLabels(_data.labels)
        }
        getData()
    }, [])

    return (
    <LabelContext.Provider value={{ labels}}>
    {children}
    </LabelContext.Provider>
    );
};

  export { useLabelContext, LabelProvider }