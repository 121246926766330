import  React, {useContext} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';
import TextField from '@mui/material/TextField';
import Rating from '@mui/material/Rating';
import Tooltip from '@mui/material/Tooltip';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import SnackBar from '../components/snackbar.js';
import WarnSelect from '../components/warnSelect';
import DelWarn from '../components/delWarn';
import ErrorIcon from '@mui/icons-material/Error';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import CopyAll from '@mui/icons-material/CopyAll';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import { useLabelContext } from '../context/labelContext.js'
const styles={
    text:{
        color:'black',
        display:'flex',
        fontWeight:'bold',
        justifyContent:'center',
        marginBottom:'10px',
        zIndex:5
    },
    code:{
        width:'50%',
        marginBottom:'10px'
    }
}

const tagMap = {
    'error': <Tooltip title="Error"><ErrorIcon style={{color:'black'}}/></Tooltip>,
    'incomplete':<Tooltip title="Incomplete"><TimerOffIcon style={{color:'black'}}/></Tooltip>,
    'duplicate':<Tooltip title="Duplicate"><CopyAll style={{color:'black'}}/></Tooltip>,
    'long_name':<Tooltip title="Long Name"><FormatItalicIcon style={{color:'black'}}/></Tooltip>,
}

const b2s=t=>{let e=Math.log2(t)/10|0;return(t/1024**(e=e<=0?0:e)).toFixed(3)+"BKMGP"[e]};
const  format2 = (n) => `0${n / 60 ^ 0}`.slice(-2) + ':' + ('0' + n % 60).slice(-2)

const getCode = (name) => {
    if (name) {
        return name.split(' ')[0]
    }
}
const shortPath = (fullPath) => {
    return fullPath && fullPath.split('/').slice(0, -1).join('/')

}
const DetailRow = ({loading, data, updateRating, setSelectedTags, saveTag, selectedTags, setShowDelWarn }) => {
    const [editRating, setEditRating] = React.useState(false)
    const changeRating = async(e, val) =>{
        updateRating(val)
        setEditRating(false)
    }
    if (data) {
            return(
            <div style={{color:'black', zIndex:5, display:'flex', alignItems:'center', flexWrap:'wrap',justifyContent:'space-around'}}>
                <Typography sx={{fontSize:{xl:'1rem !important', lg:'1rem !important', md:'1rem !important', xs: '0.8rem !important', sm: '0.8rem !important'}}}>{data?.size ? b2s(data.size) : '0' }</Typography>
                <Typography sx={{fontSize:{xl:'1rem !important', lg:'1rem !important', md:'1rem !important', xs: '0.8rem !important', sm: '0.8rem !important'}}}>{data?.length ? format2(data.length) : '0' }</Typography>
                <Typography sx={{fontSize:{xl:'1rem !important', lg:'1rem !important', md:'1rem !important', xs: '0.8rem !important', sm: '0.8rem !important'}}}>{moment(data.date).format('DD-MM-YYYY')}</Typography>
                <Typography sx={{fontSize:{xl:'1rem !important', lg:'1rem !important', md:'1rem !important', xs: '0.8rem !important', sm: '0.8rem !important'}}}>{data.resolution}</Typography>
                <div style={{display:'flex'}}>
                    <Rating name="half-rating" value={data.rating} precision={0.5} onChange={changeRating} readOnly={editRating ? false : true}/>
                    {!editRating && !loading && (<EditIcon onClick={()=>{setEditRating(true)}} style={{color:'black', marginLeft:10}} />)}
                    {!editRating && loading && (<CircularProgress size={20} style={{marginLeft:10}} />)}
                    {editRating && (<CheckIcon onClick={()=>{setEditRating(false)}} style={{color:'black', marginLeft:10}} />)}
                </div>
                <Typography sx={{fontSize:{xl:'1rem !important', lg:'1rem !important', md:'1rem !important', xs: '0.8rem !important', sm: '0.8rem !important'}}}>{data.hdd}</Typography>
                <div><ContentCopyIcon style={{color:'black'}} /></div>
                <div><WarnSelect saveTag={saveTag} selectedTags={selectedTags} setSelectedTags={setSelectedTags} inTags={data?.tags}/></div>
                <IconButton onClick={()=>{setShowDelWarn(true)}}><DeleteIcon/></IconButton>

            </div>
        )
    }
    return null;
}

const LabelSelect = ({labels, selectedLabel, setSelectedLabel}) => {
    return (
        <Box sx={{ minWidth: 120, width:"90%" }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Label</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedLabel}
                    label="Age"
                    onChange={(e)=>{setSelectedLabel(e.target.value)}}
                >
                    {Array.isArray(labels) && labels.length> 0 && labels.map(_label => (<MenuItem value={_label}>{_label}</MenuItem>)) }
                </Select>
            </FormControl>
        </Box>
    );
}

const LabelRow = ({labels, selectedLabel, setSelectedLabel, label, id}) => {
    const [editLabel, setEditLabel] = React.useState(false)
    const updateLabel = async(val) => {
        const _data = {
            label:val,
        }
        const store = localStorage.getItem("repo")
        const token = JSON.parse(store).token

        try {
            const _detail = await fetch(`/api/asset/${id}`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  "token": token
                },
                body: JSON.stringify(_data)
            })
            //const detail = await _detail.json();
        } catch (e) {
            console.log(e)
        }
    }
    return(
        <div style={{color:'black', zIndex:5, display:'flex', alignItems:'center', flexWrap:'wrap',justifyContent:'flex-start'}}>
            {!editLabel && (<Typography sx={{fontSize:{xl:'1rem !important', lg:'1rem !important', md:'1rem !important', xs: '0.8rem !important', sm: '0.8rem !important'}}}>{selectedLabel || label || "No Label"}</Typography>)}
            {!editLabel&&(<EditIcon onClick={()=>{setEditLabel(true)}} style={{color:'black', marginLeft:10}} />)}
            {editLabel&&(<LabelSelect labels={labels} selectedLabel={selectedLabel} setSelectedLabel={setSelectedLabel}/>)}
            {editLabel&&([<CheckIcon onClick={()=>{updateLabel(selectedLabel); setEditLabel(false)}} style={{color:'black', marginLeft:10}} />, <CloseIcon onClick={()=>{setSelectedLabel(label||'');setEditLabel(false)}} style={{color:'black', marginLeft:10}} />])}
        </div>
    )
}

 /*<div id="imgwrap" style={{display:'flex', flexDirection:'row', overflowX:'scroll', height:400}}>
                {screens.map(screen => <img style={{height:'100%', scrollSnapType: 'x mandatory', marginRight:10}} src={screen}/>)}
        </div>*/
const Screens = ({screens}) => {
    if (Array.isArray(screens) && screens.length > 0) {
        return (
            <AliceCarousel>
      {screens.map((image, index) => (
        <img key={index} src={image} style={{ maxWidth: '100%' }} alt={`Image ${index}`} />
      ))}
    </AliceCarousel>
        )
    }
}

const Detail = () => {
    const [data, setData] = React.useState(null)
    const [code, setCode] = React.useState(null)
    const [editMode, setEditMode] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [rateLoading, setRateLoading] = React.useState(false);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState('');
    const [selectedTags, setSelectedTags] = React.useState([]);
    const [selectedLabel, setSelectedLabel] = React.useState('');
    const [showDelWarn, setShowDelWarn] = React.useState(false)
    const { labels } = useLabelContext();
    const navigate = useNavigate();
    let { id } = useParams();
    const getDetail = async(_id) => {
        setLoading(true)
        const _detail = await fetch(`/api/asset/${_id}`)
        const detail = await _detail.json()
        setLoading(false)
        setCode(getCode(detail.title || detail.filename))
        setData(detail)
        setSelectedTags(detail?.tags??[])
        setSelectedLabel(detail?.label ?? '')
    }
    const closeSnack = () => {
        setSnackOpen(false)
    }
    const scrapeJavTrailer = async(code) => {
        setLoading(true)
        try {
            const _detail = await fetch(`/api/parse/${code}?source=javtrailer`)
            const detail = await _detail.json();
            setLoading(false)
            setData({...data, ...detail})
            setEditMode(true)
        } catch (e) {
            setSnackOpen(true);
            setSnackMessage('Could not get data')
            setLoading(false)
        }
        
        //console.log(detail)
    }
    const saveTag = async() => {
        /*
         * 
        const _data = {
            rating:val,
        }
        const store = localStorage.getItem("repo")
        const token = JSON.parse(store).token

        try {
            setRateLoading(true)
            const _detail = await fetch(`/api/asset/${data._id}`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  "token": token
                },
                body: JSON.stringify(_data)
            })
            setRateLoading(false)
            //const detail = await _detail.json();
            setEditMode(false)
            setData({...data, rating:val})
        } catch (e) {
            setSnackOpen(true);
            setSnackMessage('Could not update rating')
            setRateLoading(false)
            console.log(e)
        }
         * */
        const _data = {
            tags: selectedTags
        }
        const store = localStorage.getItem("repo")
        const token = JSON.parse(store).token
        const _detail = await fetch(`/api/asset/${data._id}`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  "token": token
                },
                body: JSON.stringify(_data)
            })

        setData({...data, tags:selectedTags})
    }

    const deleteRow = async () => {
        const store = localStorage.getItem("repo")
        const token = store && JSON.parse(store).token
        setShowDelWarn(false)
        try {            
            const _detail = await fetch(`/api/asset/${data._id}`, {
                method: "DELETE",
                headers: {
                  "Content-Type": "application/json",
                  "token": token
                },
            })
            setSnackMessage('Deleted')
            setSnackOpen(true)
        } catch (e) {
            console.log(e)
            setSnackOpen(true);
            setSnackMessage('Could not delete')
            setLoading(false)
        }
    }
    const save = async() => {
        const _data = {
            title:data.title,
            stars:data.stars,
            posterUrl: data.posterUrl,
            thumbUrls: data.thumbUrls,
        }
        const store = localStorage.getItem("repo")
        const token = store && JSON.parse(store).token
        setLoading(true)
        try {            
            const _detail = await fetch(`/api/asset/${data._id}`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  "token": token
                },
                body: JSON.stringify(_data)
            })
            const detail = await _detail.json();
            setEditMode(false)
            setLoading(false)
        } catch (e) {
            console.log(e)
            setSnackOpen(true);
            setSnackMessage('Could not save')
            setLoading(false)
        }
    }
    const updateRating = async(val) => {
        const _data = {
            rating:val,
        }
        const store = localStorage.getItem("repo")
        const token = JSON.parse(store).token

        try {
            setRateLoading(true)
            const _detail = await fetch(`/api/asset/${data._id}`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  "token": token
                },
                body: JSON.stringify(_data)
            })
            setRateLoading(false)
            //const detail = await _detail.json();
            setEditMode(false)
            setData({...data, rating:val})
        } catch (e) {
            setSnackOpen(true);
            setSnackMessage('Could not update rating')
            setRateLoading(false)
            console.log(e)
        }
    }
    React.useEffect(()=>{
        if (id) {
            getDetail(id)
        }
    }, [id])
    return (
        <Paper sx={{ width:{md:'50%', sm: '100%', xs: '100%'}, position:'absolute', backgroundColor:'white', top:0, right:0, height:'100%', zIndex:2, overflowY:'scroll'}}>
            
                <div style={{position:'absolute', cursor:'pointer', right:10, zIndex:15, top:0}} onClick={()=>{navigate('/')}}>
                    <CloseIcon style={{color:'black'}}/>
                </div>
                <div style={{color:'white', margin:'10px', display:'flex', flexDirection:'column', height:'100%'}}>
                    <div style={styles.text}> {data?.title || data?.filename} </div>
                    <Stack direction="row" spacing={2}  style={{alignItems:'center'}}> 
                        <Typography sx={{fontSize:{xl:'0.8rem !important', lg:'0.8rem !important', md:'0.8rem !important', xs: '0.6rem !important', sm: '0.6rem !important'}, color:'black'}}>{shortPath(data?.path)}</Typography>
                        {data?.tags?.map(tag=>tagMap[tag])}
                    </Stack>
                    <DetailRow setSelectedTags={setSelectedTags} selectedTags={selectedTags} saveTag={saveTag} loading={rateLoading} data={data} updateRating={updateRating} setShowDelWarn={setShowDelWarn}/>
                    <LabelRow labels={labels} selectedLabel={selectedLabel} setSelectedLabel={setSelectedLabel} label={data?.label} id={id}/>
                    <div style={{display:'flex', marginTop:10, marginBottom:10}}>
                        {data?.posterUrl && <img src={data.posterUrl} style={{maxWidth: '100%', maxHeight: '100%'}}/>}
                    </div>                                    
                    <Screens screens={data?.thumbUrls}/>                                            
                    {Array.isArray(data?.stars) && data.stars.length>0 && (<div  style={{display:'flex', flexDirection:'column', marginTop:10, marginBottom:5, width:100}}>
                        <div style={{marginBottom:10, fontWeight:'bold', color:'black'}}>Starring</div>
                        {data.stars.map((star, i)=>(<div style={{color:'black',marginBottom:5}}key={`star-${i}`}>{star}</div>))}
                    </div>)}
                    {!editMode && (<div style={{display: 'flex', flexDirection:'column', alignItems:'center', justifyContent:'flex-end', height:'90%'}}>
                    <div style={{display: 'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-evenly'}}>
                        <TextField sx={styles.code} id="outlined-basic" value={code?? ''} label="Code" onChange={(e)=>{setCode(e.target.value)}} variant="outlined" />
                        <Button onClick={()=>{scrapeJavTrailer(code)}} disabled={loading} endIcon={loading ? <CircularProgress  size={20} /> : null} variant="outlined">Get Detail</Button>
                    </div></div>)}
                    {editMode && (<div style={{width:'100%',  display: 'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-evenly'}}>
                        <Button onClick={()=>{save()}} variant="outlined" disabled={loading} endIcon={loading ? <CircularProgress  size={20} /> : null} > Save </Button>
                    </div>)}
                </div>
                <SnackBar open={snackOpen} message={snackMessage} handleClose={closeSnack}/>
                <DelWarn open={showDelWarn} setOpen={setShowDelWarn} handleDelete={deleteRow}/>
        </Paper>
    )
}

export default Detail
