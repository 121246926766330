import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import FilterListIcon from '@mui/icons-material/FilterList';
import Filters from './filters';
import { useListContext } from '../context/listContext.js'

const Asynchronous = ({setSearchKey, setSearchName }) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [searchTxt, setSearchTxt] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const { setListState, listState  } = useListContext();

  React.useEffect(() => {
    if (searchTxt) {
      let active = true;
      //setLoading(true);
      (async () => {
        const resp = await fetch(`/api/search?name=${searchTxt}`);
        const opts = await resp.json();
        if (active) {
          setOptions(opts);
          //setLoading(false);
        }
      })();

      return () => {
        active = false;
      };
    }
  }, [searchTxt]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setOpen(false);
      setListState({...listState, searchKey: searchTxt, searchName: ''})
      setOptions([]);
    }
  }
  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
        setSearchTxt('')
      }}
      
      isOptionEqualToValue={(option, value) => option.filename === value.filename}
      getOptionLabel={(option) => option.filename}
      renderOption={(props, option, state)=>{return (<div className='sugg-ind-li' onClick={(e)=>{setListState({...listState, searchName: option.filename}); setOpen(false)}}> {option.filename}</div>)}}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
        onChange={(e)=>{setSearchTxt(e.target.value)}}
        onKeyDown={handleKeyDown}
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment:(<SearchIcon/>),
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function SearchAppBar({}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            MUI
          </Typography>
          
          <Search>
            <Asynchronous/>
          </Search>
            <Box>
            <IconButton onClick={handleClick}>
                <FilterListIcon/>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Filters anchorEl={anchorEl} handleClose={handleClose}/>
    </Box>
  );
}
