import React from 'react';
import { LabelProvider } from './context/labelContext';
import { ListProvider } from './context/listContext';
import Navigation from './navigation';

//import { createTheme, ThemeProvider} from '@material-ui/core/styles';
//import { StyledEngineProvider } from '@mui/material/styles';
/*const theme = createTheme({
  overrides: {
    MuiCollapse: {
      entered: {
        height: "500px",
        overflow: "visible"
      }
    }
  },
  palette: {
    text: {
        primary:'#676767',
        secondary: '#000'
    }
  }
});
*/

class App extends React.Component {

  render() {
   return (
    <LabelProvider>
      <ListProvider>
          <Navigation /> 
      </ListProvider>
    </LabelProvider>
   )
  }
}

export default App;

/*return (
      <StyledEngineProvider>
        <ThemeProvider theme={theme}>
          <Navigation />
        </ThemeProvider>
        </StyledEngineProvider>

    )*/
