import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';

import Home from '../screens/home.js'
import Detail from '../screens/detail.js'
import Latest from '../screens/latest.js'

class Navigation extends Component {
  render() {
    return (
        <Router>
          <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route exact path="/details/:id" element={<Detail/>} />
            <Route exact path="/latest" element={<Latest/>} />
            <Route path='*' element={<Home/>} />
          </Routes>
        </Router>
    )
  }
}


export default Navigation;
