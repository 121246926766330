import React, { useState, useEffect, useRef } from 'react';
import { Box, Card, CardContent, Typography, Grid, CardActions, Tooltip, Rating, Stack, Link, CircularProgress, useMediaQuery } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTheme } from '@mui/material/styles';

const getCode = (name) => {
  if (name) {
      return name.split(' ')[0]
  }
}

const InfiniteScrollPosterGrid = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  // Fetch your data from the server or any other source
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const pageSize = isMobile ? 10 : 20
  const fetchData = async () => {
    // Replace this with your actual API call
    const response = await fetch(`/api/latest?skip=${page*10}&limit=${pageSize}`);
    const _data = await response.json();
    setData((prevData) => [...prevData, ..._data]);
    setPage((prevPage) => prevPage + 1);
    if (_data.length < pageSize) {
      setHasMore(false);
    }
  };



  // Fetch initial data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  return (

    <InfiniteScroll
    dataLength={data.length} //This is important field to render the next data
    next={fetchData}
    style={{ overflow: "hidden" }}
    hasMore={hasMore}
    loader={<CircularProgress/>}
    endMessage={
      <p style={{ textAlign: 'center' }}>
        <b>Yay! You have seen it all</b>
      </p>
    }
  >
     <Grid container spacing={2}>
        {data.map((item) => {
          return (
            <Grid item xs={12} sm={6} md={2} key={item.id}>
            <Card key={item._id}>
              <CardContent>
                <img style={{width: '100%', objectFit: 'scale-down'}} src={item.posterUrl}/>
                <Tooltip title={item.title || item.filename}>
                  <Typography variant="subtitle1" sx={{maxWdith: "100%", whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{item.title || item.filename}</Typography>
                </Tooltip>
                <Stack>
                  <Rating name="half-rating" defaultValue={item.rating} precision={0.5} readOnly/>
                </Stack>
                {/* Add more fields as needed */}
              </CardContent>
              {getCode(item.title || item.name) && (<CardActions disableSpacing>
                <Link sx={{ mr: 5 }} target="_blank" rel="noreferrer" href={`https://javhd.today/search/video/?s=${getCode(item.title || item.name)}`}>Server 1</Link>
                <Link target="_blank" rel="noreferrer" href={`https://sextb.net/search/${getCode(item.title || item.name)}`}>Server 2</Link>
              </CardActions>)}
            </Card>   
            </Grid>       
        )})}
        </Grid>
        </InfiniteScroll>
  );
};

export default InfiniteScrollPosterGrid;
