import  React, { useState, useEffect, useRef} from 'react';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import moment from 'moment';
import AppBar from './appbar.js';
import { useNavigate } from 'react-router-dom';
import { useListContext } from '../context/listContext.js'
import { Chip, Stack, Rating, Tooltip, Typography, useMediaQuery } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { useTheme } from '@mui/material/styles';

const  format2 = (n) => `0${n / 60 ^ 0}`.slice(-2) + ':' + ('0' + n % 60).slice(-2)
const b2s=t=>{let e=Math.log2(t)/10|0;return(t/1024**(e=e<=0?0:e)).toFixed(3)+"BKMGP"[e]};
const getRes = res => res.toLowerCase().split("x")[1].trim() + "p"

const columns = [
  { field: 'filename', headerName: 'Name', width: 1000, renderCell: (params) =>  (
    <Tooltip title={params.row?.path} >
      <Stack direction="row" alignItems="center">
        <Chip sx={{mr:2}} label={getRes(params.row.resolution)}/>
        <span className="table-cell-truncate">{params.row?.title || params.row?.filename }</span>        
     </Stack>
     </Tooltip>
   ) },
  //{ field: 'resolution', headerName: 'Resolution', width: 130, sortable: false },
  { field: 'length', headerName: 'Length', width: 100, valueGetter: (value, row) =>{ return`${format2(row?.length) }`}},
  { field: 'date', headerName: 'Last Modified', width: 100, valueGetter: (value, row) =>`${moment(row?.date).format('MMM YY') }` },
  { field: 'size', headerName: 'Size', width: 130, valueGetter: (value, row) =>`${b2s(row?.size) }` },
  { field: 'rating', headerName: 'Rating', width: 130, renderCell: (params, row) => < Rating name="half-rating" defaultValue={params.row?.rating} precision={0.5} readOnly/>},
  { field: 'hdd', headerName: 'Source', width: 130, sortable: false },
  { field: 'stars', headerName: 'Stars', sortable: false, width: 130, valueGetter: (value, row) =>`${(row?.stars ? row?.stars.join(',') : '') }`}
];

const mobileColumns = [
  { field: 'filename', headerName: 'Name', width: 150, renderCell: (params) =>  (
    <Tooltip title={params.row?.path} >
      <Stack direction="row" alignItems="center">
        <Chip sx={{mr:1}} label={getRes(params.row.resolution)}/>
        <span className="table-cell-truncate">{params.row?.title || params.row?.filename }</span>
     </Stack>
     </Tooltip>
   ) },
   { field: 'rating', headerName: 'Rating', width: 80, renderCell: (params, row) => <Stack sx={{height: '100%'}}direction="row" alignItems="center" justifyContent="center"><Typography sx={{mr: 1}}>{params.row?.rating}</Typography><StarIcon sx={{ color: '#FFD700' }}/> </Stack>},
   { field: 'date', headerName: 'Modified', width: 70, valueGetter: (value, row) =>`${moment(row?.date).format('MMM YY') }` },
  //{ field: 'resolution', headerName: 'Resolution', width: 130, sortable: false },
  { field: 'length', headerName: 'Length', width: 70, valueGetter: (value, row) =>{ return`${format2(row?.length) }`}},
  
  
  { field: 'hdd', headerName: 'Source', width: 70, sortable: false },
];

export default function DataTable() {        
  
    const { listData, setListState, listState, loading, setLoading  } = useListContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const apiRef = useGridApiRef();
    const [paginationModel, setPaginationModel] = React.useState({
      pageSize: 100,
      page: 0,
    });
    
    const handleSortModelChange = (model) =>{
        if (Array.isArray(model) && model.length > 0) {
            const [{field, sort}] = model;
            setListState({...listState, sortField: field, sortDir: sort})
        }
    }

    const navigate = useNavigate();
    const handleOnCellClick = (params, event, details) => {      
      localStorage.setItem('focus', apiRef.current.getScrollPosition().top);
      navigate(`/details/${params.id}`)
    }

    useEffect(() => {
      setTimeout(() => {
        apiRef.current.scroll({
          top: localStorage.getItem('focus'),
          left: 0,
        });
      });
    }, [apiRef.current]);



  React.useEffect(() => {
    if (paginationModel.page > 0) {
    let active = true;
    
    (async () => {
      setLoading(true);
      setListState({...listState, page: paginationModel.page})

      if (!active) {
        return;
      }
      setLoading(false)
    })();

    return () => {
      active = false;
    };
  }
  }, [paginationModel.page]);

  return (
    <div style={{ height: 'calc(100vh - 64px)', width: '100%' }}>
      <AppBar/>
      <DataGrid
        apiRef={apiRef}
        rows={listData.result}
        columns={isMobile ? mobileColumns : columns}
        getRowId={row => row._id}
        pageSize={100}        
        rowCount={listData.total}
        sortingOrder={['desc', 'asc']}
        paginationMode="server"
        sortingMode="server"
        onCellClick={handleOnCellClick}
        onSortModelChange={handleSortModelChange}
        rowsPerPageOptions={[50]}
        loading={loading}
        disableVirtualization={true}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
      />
    </div>
  );
}
