import  React, {useContext} from 'react';
import List from '../components/list.js'

function Home() {    
    return (
    <div className="App">
      <List/>
    </div>
    );
}

export default Home;
